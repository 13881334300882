var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"tambah-pengajuan-master-data-informasi"}},[_c('b-card',{attrs:{"header":"Tambah Master Data dan Informasi","header-tag":"h4","header-class":"border-bottom mb-2"}},[(_vm.alert)?[_c('b-alert',{attrs:{"variant":_vm.alertDetail.variant,"show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.alertDetail.title)+" ")]),(_vm.alertDetail.variant === 'danger' && _vm.alertDetail.errors !== '')?_c('div',{staticClass:"alert-body"},[(typeof _vm.alertDetail.errors === 'object')?_c('ul',_vm._l((_vm.alertDetail.errors),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "+_vm._s(item)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.alertDetail.text + ': ' + _vm.alertDetail.errors))])]):_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.alertDetail.text)+" ")])])]:_vm._e(),_c('validation-observer',{ref:"formBisnis"},[_c('form',{attrs:{"id":"editMasterBisnis"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Data Pokok","label-for":"vi-data_basic_id"}},[_c('validation-provider',{attrs:{"name":"Data Pokok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_basic_id","required":"","name":"data_basic_id","options":_vm.referenceData.ref_data_pokok,"placeholder":"Data Pokok","label":"nama"},on:{"input":function($event){return _vm.getDataTematik()}},model:{value:(_vm.data_basic_id),callback:function ($$v) {_vm.data_basic_id=$$v},expression:"data_basic_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Data Tematik","label-for":"vi-data_tematic_id"}},[_c('validation-provider',{attrs:{"name":"Data Tematik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_tematic_id","required":"","name":"data_tematic_id","options":_vm.daftarDataTematik,"placeholder":"Data Tematik","label":"nama"},on:{"input":_vm.getDataTopik},model:{value:(_vm.data_tematic_id),callback:function ($$v) {_vm.data_tematic_id=$$v},expression:"data_tematic_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'),expression:"'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Data Topik","label-for":"vi-data_topic_id"}},[_c('validation-provider',{attrs:{"name":"Data Topik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.fungsi_and_sub)?_c('v-select',{attrs:{"id":"vi-data_topic_id","required":"","name":"data_topic_id","options":_vm.daftarDataTopik,"placeholder":"Data Topik","label":"nama"},on:{"input":_vm.getDataSubTopik},model:{value:(_vm.data_topic_id),callback:function ($$v) {_vm.data_topic_id=$$v},expression:"data_topic_id"}}):_vm._e(),(_vm.fungsi_and_sub)?_c('b-input-group',[(_vm.fungsi_and_sub)?_c('b-form-input',{attrs:{"id":"vi-data_topic_id","required":"","name":"data_topic_id","placeholder":"Data Topik"},model:{value:(_vm.data_topic_nama),callback:function ($$v) {_vm.data_topic_nama=$$v},expression:"data_topic_nama"}}):_vm._e(),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('cat')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'),expression:"'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Data Sub Topik","label-for":"vi-sub_data_topic_id"}},[_c('validation-provider',{attrs:{"name":"Data Sub Topik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.sub_fungsi)?_c('v-select',{attrs:{"id":"vi-sub_data_topic_id","required":"","name":"sub_data_topic_id","options":_vm.daftarDataSubTopik,"placeholder":"Data Sub Topik","label":"nama"},on:{"input":_vm.setSubFungsiGov},model:{value:(_vm.sub_data_topic_id),callback:function ($$v) {_vm.sub_data_topic_id=$$v},expression:"sub_data_topic_id"}}):_vm._e(),(_vm.sub_fungsi)?_c('b-input-group',[(_vm.sub_fungsi)?_c('b-form-input',{attrs:{"id":"vi-sub_data_topic_id","required":"","name":"sub_data_topic_id","placeholder":"Data Sub Topik"},model:{value:(_vm.sub_data_topic_nama),callback:function ($$v) {_vm.sub_data_topic_nama=$$v},expression:"sub_data_topic_nama"}}):_vm._e(),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('sub')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'),expression:"'Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Data","label-for":"vi-nama"}},[_c('validation-provider',{attrs:{"name":"Nama Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama","required":"","name":"nama","placeholder":"Nama Data"},model:{value:(_vm.nama),callback:function ($$v) {_vm.nama=$$v},expression:"nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'),expression:"'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Interoperabilitas Data","label-for":"vi-data_interoperability_id"}},[_c('validation-provider',{attrs:{"name":"Interoperabilitas Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_interoperability_id","required":"","name":"data_interoperability_id","options":_vm.daftarDataInteroperability,"placeholder":"Interoperabilitas Data","label":"nama"},model:{value:(_vm.data_interoperability_id),callback:function ($$v) {_vm.data_interoperability_id=$$v},expression:"data_interoperability_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan klasifikasi data berdasarkan bentuk datanya.'),expression:"'Merupakan klasifikasi data berdasarkan bentuk datanya.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sifat Data","label-for":"vi-data_status_id"}},[_c('validation-provider',{attrs:{"name":"Sifat Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_status_id","required":"","name":"data_status_id","options":_vm.referenceData.ref_sifat_data,"placeholder":"Sifat Data","label":"nama"},model:{value:(_vm.data_status_id),callback:function ($$v) {_vm.data_status_id=$$v},expression:"data_status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan tujuan dari pemanfaatan data.'),expression:"'Merupakan penjelasan tujuan dari pemanfaatan data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Tujuan Data","label-for":"vi-tujuan"}},[_c('validation-provider',{attrs:{"name":"Tujuan Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-tujuan","rows":"4","required":"","name":"tujuan","placeholder":"Tujuan Data"},model:{value:(_vm.tujuan),callback:function ($$v) {_vm.tujuan=$$v},expression:"tujuan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tanggal mulai berlakunya data.'),expression:"'Merupakan tanggal mulai berlakunya data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Awal Validitas Data","label-for":"vi-valid_start"}},[_c('validation-provider',{attrs:{"name":"Awal Validitas Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"vi-valid_start","max":_vm.valid_end,"date-format-options":{ year: 'numeric', month: 'short', day: 'numeric' },"required":"","name":"valid_start","placeholder":"Awal Validitas Data"},model:{value:(_vm.valid_start),callback:function ($$v) {_vm.valid_start=$$v},expression:"valid_start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tanggal akhir berlakunya data.'),expression:"'Merupakan tanggal akhir berlakunya data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Akhir Validitas Data","label-for":"vi-valid_end"}},[_c('validation-provider',{attrs:{"name":"Akhir Validitas Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"vi-valid_end","min":_vm.valid_start,"date-format-options":{ year: 'numeric', month: 'short', day: 'numeric' },"required":"","name":"valid_end","placeholder":"Akhir Validitas Data"},model:{value:(_vm.valid_end),callback:function ($$v) {_vm.valid_end=$$v},expression:"valid_end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penanggung jawab dari data.'),expression:"'Merupakan penanggung jawab dari data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Penanggung Jawab","label-for":"vi-pic_name"}},[_c('validation-provider',{attrs:{"name":"Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-pic_name","required":"","name":"pic_name","placeholder":"Penanggung Jawab"},model:{value:(_vm.pic_name),callback:function ($$v) {_vm.pic_name=$$v},expression:"pic_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'),expression:"'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Uraian Data","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Uraian Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","rows":"4","required":"","name":"deskripsi","placeholder":"Uraian Data"},model:{value:(_vm.deskripsi),callback:function ($$v) {_vm.deskripsi=$$v},expression:"deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan wali dari data.'),expression:"'Merupakan wali dari data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Wali Data","label-for":"vi-wali_data"}},[_c('validation-provider',{attrs:{"name":"Wali Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-wali_data","required":"","name":"wali_data","placeholder":"Wali Data"},model:{value:(_vm.walidata),callback:function ($$v) {_vm.walidata=$$v},expression:"walidata"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]),_c('b-button',{staticClass:"ml-50",attrs:{"to":{ path: '/pengajuan/master/data-informasi' },"variant":"outline-secondary"}},[_vm._v(" Kembali ")])],1)],1)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }